import * as React from 'react';
import './Footer.scss';


export const Footer: React.VoidFunctionComponent = () => {
  return <footer>
    <p className="container text-center">© 2021 Alexey Ulashchick. All rights reserved.</p>
    <div className="container text-center icons">
      Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from&nbsp;
      <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </div>
  </footer>;
};
