import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Icon from './../../images/svg/icon.svg';
import './Header.scss';
import {useEffect} from 'react';
import {Link} from 'gatsby';
import {QAllGalleries} from '../QAllGalleries/QAllGalleries';
import {AllGalleries_allGalleriesJson_nodes} from '../QAllGalleries/__generated__/AllGalleries';
import { slugify } from '../../utils/slug';

const InnerHeader = () => (
  <Navbar className="my-navbar" expand="lg" variant="dark">
    <Navbar.Brand className="logo">
      <Link to="/">
        <Icon height="3em" />
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center" role="">
      <Nav className="navItems">
        <Link className="nav-link" to="/">Home</Link>
        <NavDropdown title="Galeries" id="basic-nav-dropdown">
          <QAllGalleries render={(allGalleries: AllGalleries_allGalleriesJson_nodes[]) =>
            <>
              {allGalleries
                  .map((gallery: AllGalleries_allGalleriesJson_nodes) => gallery.galleryName ?? 'Error')
                  .map((galeryName: string) => (
                    <Link className="dropdown-item" to={`/${slugify(galeryName.toLowerCase())}/`} key={galeryName}>
                      {galeryName}
                    </Link>
                  ))}
            </>
          }/>
        </NavDropdown>
        <Link className="nav-link" to="/">Gear</Link>
        <Link className="nav-link" to="/guides/">Photo Guides</Link>
        <Link className="nav-link" to="/">Contact</Link>
      </Nav>
    </Navbar.Collapse>
    <Navbar.Text className="my-social-icons text-align-right">
      <Link to="/">
        <i className="fa fa-twitter"></i>
      </Link>
      <Link to="/">
        <i className="fa fa-facebook-f"></i>
      </Link>
      <Link to="/">
        <i className="fa fa-instagram"></i>
      </Link>
    </Navbar.Text>
  </Navbar>
);

export const Header: React.VoidFunctionComponent = () => {
  useEffect(() => {
    const FIXED_VISIBLE = 'fixed--visible';
    const navbar = document.getElementById('sliding-header');
    const onScroll = () => {
      if (window.scrollY >= window.innerHeight / 3) {
        navbar?.classList.add(FIXED_VISIBLE);
      } else {
        navbar?.classList.remove(FIXED_VISIBLE);
      }
    };

    onScroll();
    window.addEventListener('scroll', () => requestAnimationFrame(() => onScroll()));
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <InnerHeader/>
      <div className="sliding-header" id="sliding-header">
        <InnerHeader/>
      </div>
    </>
  );
};
