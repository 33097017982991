import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react'
import {AllGalleries, AllGalleries_allGalleriesJson_nodes} from './__generated__/AllGalleries';

const query=graphql`
  query AllGalleries {
    allGalleriesJson {
      nodes {
        galleryName
        imageName
      }
    }
  }
  `;

type QAllGalleriesProps = {
  render: (allImages: AllGalleries_allGalleriesJson_nodes[]) => React.ReactNode;
}

export const QAllGalleries: React.FC<QAllGalleriesProps> = ({render}: QAllGalleriesProps) => {
  return <StaticQuery
    query={query}
    render={(data: AllGalleries) => render(data.allGalleriesJson.nodes)}/>;
};

